import { CoType } from "./apiinterfaces";
import { TransformPartsUI, TransformFile } from "./ui.models";
import { Customer } from "./customer";

export enum InvFileTypes {
  Customer = "Customer",
  Item = "Item",
  Transact = "Transact",
  Detail = "Detail",

  CustomerTXT = "CustomerTXT",
  ItemTXT = "ItemTXT",

  SalesOrderHeader = "SalesOrderHeader",
  SalesOrderDetail = "SalesOrderDetail",

  Null = "Null",

  //For FB/Odata
  ReturnDetail = "ReturnDetail",
  ReturnHeader = "ReturnHeader",

  SampleHeader = "SampleHeader",
  SampleDetail = "SampleDetail",
  SampleTasting = "SampleTasting",

  SignedReceiptUrl = "SignedReceiptUrl",
  PaymentJournal = "PaymentJournal",

  HotshotCustPickupDetail = "HotshotCustPickupDetail",
  HotshotCustPickupHeader = "HotshotCustPickupHeader",
  HotshotRepPickupDetail = "HotshotRepPickupDetail",
  HotshotRepPickupHeader = "HotshotRepPickupHeader",
  ReshipReturnHeader = "ReshipReturnHeader",
  ReshipReturnDetail = "ReshipReturnDetail",
  ReshipSalesHeader = "ReshipSalesHeader",
  ReshipSalesDetail = "ReshipSalesDetail",
  PickupHeader = "PickupHeader",
  PickupDetail = "PickupDetail",

  //used by GENERATE FILE for PD app... afer add .fil MUST BE 18,20, or 22 characters
  TransactPDRoutes = "TransactPDRoutes",
  //transact with only returns that is sent a a different time
  TransactPDReturn = "TransactPDReturn",
  TransactPDKegRet = "TransactPDKegRet",
  TransactPDKegDep = "TransactPDKegDep",
  TransactMT = "TransactMT",
  TransactPAU = "TransactPAU",
  TransactPDCredit = "TransactPDCredit",
  //TransactPDDropOf = "TransactPDDropOf",
  TransactDO = "TransactDO",
  CustomerExt = "CustomerExt",
  ItemExt = "ItemExt",

  EdiOrderFile = "EdiOrderFile",
  EdiOrderDetail = "EdiOrderDetail",
  EdiOrderHeader = "EdiOrderHeader",
  EdiOrderTotalWines = "EdiOrderTotalWines",
  OrderConfirmation = "OrderConfirmation",

  CoopOrderHeader = "CoopOrderHeader",
  CoopOrderDetail = "CoopOrderDetail",
  CoopOrderDetailInfo = "CoopOrderDetailInfo",

  BillHoldSalesOrderHeader = "BillHoldSalesOrderHeader",
  BillHoldSalesOrderDetail = "BillHoldSalesOrderDetail",
  BillHoldReturnOrderHeader = "BillHoldReturnOrderHeader",
  BillHoldReturnOrderDetail = "BillHoldReturnOrderDetail",

  //for FB PDF urls, and Opici
  Receipt = "Receipt",

  //for lipman map their SQL in GK to their PA Server
  EIS3_ChannelPrice101 = "EIS3_ChannelPrice101",
  EIS3_ChannelPrice102 = "EIS3_ChannelPrice102",

  EIS3_Deal101v2 = "EIS3_Deal101v2",
  EIS3_Deal102v2 = "EIS3_Deal102v2",
  EIS3_Deal103v2 = "EIS3_Deal103v2",
  EIS3_Deal101Who = "EIS3_Deal101Who",

  EIS3_Deal3Header = "EIS3_Deal3Header",
  EIS3_Deal3Detail = "EIS3_Deal3Detail",
  EIS3_Deal3Who = "EIS3_Deal3Who",
  EIS3_FrontLinePricing = "EIS3_FrontLinePricing",
  PockAdv_PocketRepOrder = "PockAdv_PocketRepOrder",
  EdiOrderSevenFifty = "EdiOrderSevenFifty",
  EdiOrderCraftable = "EdiOrderCraftable",
  EdiOrderSalesforce = "EdiOrderSalesforce",
  EdiOrderProvi = "EdiOrderProvi",
}

export interface Schedule {
  HumanDescription: string;
  PullFromCustomerEndpointName: string;
  WriteToInventivFileName: string;
  awscoid: number;
  LambdaName: string;
  isActive: boolean;
  TimeIntervalCronCST: string;
  CustomerName: string;
  Notes: string;
  GUID: string;
  //AwsCoId: number;
  ExtraParam1: string | null;
  EndpointName: string | null; //eg InventivItems (pull from FB)
  DateLastRunUTC: string | null;
  DateNextRunUTC: string | null;
  MinNumberOfRecordsExpected: number | null;
}

export class InvFileSpec {
  name: string = "";
  customer: string = ""; //eg Murphy or All
  collist: InvFileSpecCol[] = []; //{val:'CompanyID',type:'int'}
}
export class InvFileSpecCol {
  //{val:'CompanyID',type:'int'}
  val: string; //aka colname
  type: string; //aka cast

  //https://stackoverflow.com/a/37682352/1634753
  public constructor(init?: Partial<InvFileSpecCol>) {
    Object.assign(this, init);
  }
}
//-----------------------------------------------------------
export class Transform {
  val: string;
  note: string;
}
export interface TransformInterface {
  val: string;
  note: string;
}

//from SQL table
export interface Settings {
  sqlquery: string;
}

export interface TransformV2SqlRecord {
  CustomerName: string;
  strTransforms: string;
  objTransforms: TransformParts[]; //string parsed into TRansformParts object
  DateSaved: Date;
  LastUser: string;
  AwsCoId: string;
}

export class TransformParts {
  public outputtablename: string = ""; //Name of Inventiv file or (occasionally) sql table eg SalesOrderHeader
  public inputtablename: string = "no table specified"; //FORMERLY STARTTABLE. ALWAYS sql
  public filetype: InvFileTypes;
  public transforms: Transform[] = [];
  public customer: Customer;

  public allrowtable: string; //USED IN LAMBDA ONLY NOT UI. UI this is EMPTY
  public name: string = "";
  allrowoutputtable: string;

  constructor(_name: string, init: Partial<TransformParts | TransformPartsUI>) {
    //clog("TransformPart constructor:" + typeof init); //JSON.stringify(init));
    Object.assign(this, init);
    if (!this.name || this.name.length <= 0) this.name = _name;
  }

  toString(): string {
    if (!this.transforms)
      console.warn(`---err serializing transform: ${this.name}--`);
    return (
      `"${this.name}":{"transforms":[${(this.transforms as Transform[])
        .map((k: Transform) => `{"val":"${k.val}","note":"${k.note}"}`)
        .join(",")}],` +
      `"inputtablename":"${this.inputtablename.replace(/\r?\n/, "")}",` +
      `"outputtablename":"${(this.outputtablename || "").replace(
        /\r?\n/,
        ""
      )}",` +
      `"filetype":"${this.filetype}"` +
      "}"
    );
  }
}

export enum CustConnTypes {
  gk = "gk",
  inventiv = "inventiv",
}

export interface PaulJWT {
  email: string;
  customerNames: string; //comma seperated list of customerNames
}

// export class PDF {
//   pages: object[]; //array of page where PAGE: {"2.85":["Invoice"],"4.38":["Date","Invoice #"],"5.76":["3/5/2020","22294"],"8.325":["Ship To"],"9.45":["Sushi Note"],
//   co: CoType; //which parser to use
//   filename: string;
//   removeFinalLine: boolean;
//   customername: string;
//   writeSql: boolean;

//   static PdfList: PDF[] = [
//     {
//       co: CoType.OP,
//       filename: "OP-INVL_32.pdf",
//       customername: "Convoy",
//       removeFinalLine: true,
//       writeSql: true,
//       pages: [],
//     },
//     {
//       co: CoType.CBA,
//       filename: "CBA SOUTH 11 orders 3-5 V59.pdf",
//       customername: "Convoy",
//       removeFinalLine: false,
//       writeSql: true,
//       pages: [],
//     },
//     {
//       co: CoType.VER,
//       filename: "Vervet x Winsome #1047.pdf",
//       customername: "Convoy",
//       removeFinalLine: false,
//       writeSql: true,
//       pages: [],
//     },
//   ];
// }
